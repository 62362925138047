import React from "react";

import { withFirebase } from "../firebase";

import { useEffect, useState } from "react";

import { Card, Row, Col, Button } from 'react-bootstrap';

function WorkshopSelector(props) {
    const [selected, setSelected] = useState([]);
  
    useEffect(() => {
      /*props.firebase.voter(props.uid).child(props.id).get(snapshot => {
        if (snapshot.exists()) {
        }
      });*/
      props.firebase.signup(props.uid).child(props.id).on('value', snapshot => {
        if (snapshot.exists()) {
          setSelected(snapshot.val());
        }
      });
      // return a function to execute at unmount
      return () => {
        props.firebase.signup(props.uid).child(props.id).off();
      }
    }, []);

    const enroll = (e) => {
        props.firebase.signup(props.uid).child(props.id).set({
            uid: e.currentTarget.getAttribute("data-value"),
        });
        setSelected({
            uid: e.currentTarget.getAttribute("data-value"),
        });
    };

    const unenroll = () => {
        props.firebase.signup(props.uid).child(props.id).remove();
        setSelected([]);
    };
  
    return (
        <div><Row>{props.content.map(workshop => (
            <Card style={{ width: '17rem', margin: '0.5rem'}}>
            <Card.Body>
              <Card.Title>{workshop.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Enrollment: {props.tally[workshop.uid] == null ? 0 : props.tally[workshop.uid]} / {workshop.capacity}</Card.Subtitle>
              <Card.Link href={`/view/${workshop.uid}`}>Read Description</Card.Link>
              <br /><br /> 
              {(((props.tally[workshop.uid] == null ? 0 : props.tally[workshop.uid]) >= parseInt(workshop.capacity) || props.disable) && workshop.uid !== selected.uid && props.active == true) && <Button variant="success" disabled>Enroll</Button>}
              {(!props.disable && (props.tally[workshop.uid] == null ? 0 : props.tally[workshop.uid]) < parseInt(workshop.capacity) && selected.length == 0 && props.active == true) && <Button variant="success" onClick={enroll} data-value={workshop.uid}>Enroll</Button>}
              {(!props.disable && workshop.uid === selected.uid && props.active == true) && <Button variant="danger" onClick={unenroll}>Unenroll</Button>}
              {(!props.disable && (props.tally[workshop.uid] == null ? 0 : props.tally[workshop.uid]) < parseInt(workshop.capacity) && workshop.uid !== selected.uid && selected.length != 0 && props.active == true) && <Button variant="success" disabled>Enroll</Button>}
              {(props.active == false && workshop.uid === selected.uid) && <Card.Body>You are enrolled in this workshop.</Card.Body>}
            </Card.Body>
          </Card>
        ))}</Row></div>
    );
  }

  export default withFirebase(WorkshopSelector);