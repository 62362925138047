import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { withFirebase } from '../firebase';

import { withAuthorization, AuthUserContext } from '../session';

import { compose } from 'recompose';

import { Table } from 'react-bootstrap';

import * as ROLES from '../../constants/roles';


function ViewRoster(props) {
    const [loading, setLoading] = useState(true);
    const [workshop, setWorkshop] = useState(null);
    const [users, setUsers] = useState([]);
    const [list, setList] = useState([]);

    let { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        props.firebase.workshop(id).get().then(snapshot => {
            if (!snapshot.exists()) {
                navigate('/404', {replace:true});
            }
        });
        props.firebase.users().on('value', snapshot => {
            setList(snapshot.val());
        });
        props.firebase.workshop(id).on('value', snapshot => {
            setWorkshop(snapshot.val());
            setLoading(false);
        });
        props.firebase.signups().on('value', snapshot => {
            const content = snapshot.val();
            
            if (snapshot.exists()) {
                const people = Object.keys(content).map(key => ({
                    ...content[key],
                    uid: key,
                }));
                var bruh = [];
                for (let i = 0; i < people.length; ++i) {
                    for (let j = 1; j <= 6; ++j) {
                        if (people[i][j] != null && id === people[i][j].uid) {
                            bruh.push(people[i].uid);
                        }
                    }
                }
                setUsers(bruh);
                console.log(bruh);
            }
        });
        // return a function to execute at unmount
        return () => {
            props.firebase.workshop(id).off();
            props.firebase.users().off();
            props.firebase.signups().off();
        }
      }, []);
    return (

        <div>
            {loading && <div>loading... </div>}
            {!loading && 
            <div>
                <h1>{workshop.title}</h1>
                <br />
                <h3>To view a user's workshops, click on their User ID.</h3>
                <br />
                <p>{users.length} / {workshop.capacity} signed up</p>
                <br />
                <UsersList users={list} uidList={users}></UsersList>
            </div>}
        </div>

    );
}

const UsersList = ({ users, uidList }) => (
    <div>
        
        <Table striped bordered hover>
        <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Grade</th>
          <th>Email</th>
          <th>User ID</th>
        </tr>
      </thead>
      <tbody>
        {uidList.map(uid => (
            <tr>
            <td>{users[uid].first}</td>
            <td>{users[uid].last}</td>
            <td>{users[uid].grade}</td>
            <td>{users[uid].email}</td>
            <td><a href={`/admin/viewschedule/${uid}`}>{uid}</a></td>
          </tr>
        ))}
        </tbody>
        </Table>
    </div>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];


export default compose(withFirebase, withAuthorization(condition), )(ViewRoster)