import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../firebase";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                authUser: JSON.parse(localStorage.getItem('authUser')),
            };
        }
        
        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(authUser => {
                this.setState({ authUser });
                localStorage.setItem('authUser', JSON.stringify(authUser));
                console.log(authUser);
            }, authSuccess => {
                this.setState({ authUser: null });
                console.log(authSuccess);
                localStorage.removeItem('authUser');
                if (authSuccess) {
                    this.props.navigate('/', {replace:true});
                }
            },);
        }
        
        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;