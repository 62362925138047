import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';

import { Card, Row } from 'react-bootstrap';

class HomePage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            content: [],
            content1: [],
            content2: [],
            content3: [],
            content4: [],
            content5: [],
            content6: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    const workshopsList = Object.keys(content).map(key => ({
                        ...content[key],
                        uuid: key,
                    })).reverse();
                    
                    this.setState({
                        content: workshopsList,
                        content1: workshopsList.filter((e) => e.category === "1"),
                        content2: workshopsList.filter((e) => e.category === "2"),
                        content3: workshopsList.filter((e) => e.category === "3"),
                        content4: workshopsList.filter((e) => e.category === "4"),
                        content5: workshopsList.filter((e) => e.category === "5"),
                        content6: workshopsList.filter((e) => e.category === "6"),
                        loading: false,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.workshops().off();
    }

    render() {
        const { error, content, content1, content2, content3, content4, content5, content6, loading} = this.state;
        return (
            <div>
                <h1>Workshops</h1>
                <br />
                <h5>The Program of Workshops Google Doc can be found <a href="https://docs.google.com/document/d/1VBsvuTP7UNeGSE9NTu2oQs2NJxviRSDr2_bhfNZiDjU/edit" target="_blank" rel="noopener noreferrer">here</a>.</h5>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <h3>Period 1</h3>
                    <br />
                    <WorkshopsList workshops={content1} />
                    <br />
                    <h3>Periods 1 and 2 (Double Period)</h3>
                    <br />
                    <WorkshopsList workshops={content4} />
                    <br />
                    <h3>Period 2</h3>
                    <br />
                    <WorkshopsList workshops={content2} />
                    <br />
                    <h3>Period 3</h3>
                    <br />
                    <WorkshopsList workshops={content3} />
                    <br />
                    <h3>Periods 3 and 4 (Double Period)</h3>
                    <br />
                    <WorkshopsList workshops={content6} />
                    <br />
                    <h3>Period 4</h3>
                    <br />
                    <WorkshopsList workshops={content5} />
                    <br />
                </div>}
            </div>

        );
    }
}

const WorkshopsList = ({ workshops }) => (
    <div>
        <Row>{workshops.map(workshop => (
            <Card style={{ width: '17rem', margin: '0.5rem' }}>
            <Card.Body>
              <Card.Title>{workshop.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Period(s): {workshop.periods}</Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">Location: {workshop.location}</Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">{workshop.length == "2" ? <div>This is a double-period workshop.</div> : <div>This is a single-period workshop.</div>}</Card.Subtitle>
              <Card.Link href={`/view/${workshop.uid}`}>Read Description</Card.Link>
            </Card.Body>
          </Card>
        ))}</Row>
    </div>
);

export default compose(withFirebase, )(HomePage);