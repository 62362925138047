import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';

import { AuthUserContext, withAuthorization } from '../session';

import * as ROLES from '../../constants/roles';
import WorkshopSelector from './backend';

const SignUp = () => (
    <AuthUserContext.Consumer>
        {authUser => (
      <div>
        <SignUpBase authUser={authUser} />
      </div>)}
    </AuthUserContext.Consumer>
);

// peepeepoopoopants

class SignUpStuff extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            content: [],
            content1: [],
            content2: [],
            content3: [],
            content4: [],
            content5: [],
            content6: [],
            tally: [],
            error: false,
            active: false,
            disable12: false,
            disable4: false,
            disable34: false,
            disable6: false,
            error_msg: '',
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.signup_live().on('value', snapshot => {
            const content = snapshot.val();
            if (content.value == "0") {
                this.setState({ active: false });
            } else {
                this.setState({ active: true });
            }
        });
        /*this.props.firebase.signup(this.props.authUser.uid).on('value', snapshot => {
            if (snapshot.exists()) {
                if (snapshot.val()[1] != null || snapshot.val()[2] != null) {
                    this.setState({disable4: true});
                } else {
                    this.setState({disable4: false});
                }
                if (snapshot.val()[4] != null) {
                    this.setState({disable12: true});
                } else {
                    this.setState({disable12: false});
                }   
                //this.setState({disable12: true});
            } else {
                this.setState({disable12: false, disable4: false});
            }
        });*/
        

        this.props.firebase.signups().on('value', snapshot => {
            const content = snapshot.val();
            /*this.props.firebase.workshops().get().then(snapshot => {
                const content = snapshot.val();
                if (snapshot.exists()) {
                    const workshopsList = Object.keys(content);
                    for (let i = 0; i < workshopsList.length; ++i) {
                        dict[workshopsList[i]] = 0;
                    }
                }
            });*/
            var dict = {};
            if (snapshot.exists()) {
                if (snapshot.val()[this.props.authUser.uid] == null) {
                    this.setState({disable12: false, disable4: false, disable34: false, disable6: false});
                } else {
                    if (snapshot.val()[this.props.authUser.uid][1] != null || snapshot.val()[this.props.authUser.uid][2] != null) {
                        this.setState({disable4: true});
                    } else {
                        this.setState({disable4: false});
                    }
                    if (snapshot.val()[this.props.authUser.uid][3] != null || snapshot.val()[this.props.authUser.uid][5] != null) {
                        this.setState({disable6: true});
                    } else {
                        this.setState({disable6: false});
                    }
                    if (snapshot.val()[this.props.authUser.uid][4] != null) {
                        this.setState({disable12: true});
                    } else {
                        this.setState({disable12: false});
                    }
                    if (snapshot.val()[this.props.authUser.uid][6] != null) {
                        this.setState({disable34: true});
                    } else {
                        this.setState({disable34: false});
                    }
                }
                
                const people = Object.keys(content).map(key => ({
                    ...content[key],
                    uid: key,
                }));
                for (let i = 0; i < people.length; ++i) {
                    for (let j = 1; j <= 6; ++j) {
                        if (people[i][j] != null) {
                            if (dict[people[i][j].uid] == null) {
                                dict[people[i][j].uid] = 1;
                            } else {
                                dict[people[i][j].uid] += 1;
                            }
                        }
                    }
                }
                
            }
            this.setState({tally: dict});
            
        });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    const workshopsList = Object.keys(content).map(key => ({
                        ...content[key],
                        uuid: key,
                    })).reverse();
                    this.setState({
                        content: workshopsList,
                        content1: workshopsList.filter((e) => e.category === "1"),
                        content2: workshopsList.filter((e) => e.category === "2"),
                        content3: workshopsList.filter((e) => e.category === "3"),
                        content4: workshopsList.filter((e) => e.category === "4"),
                        content5: workshopsList.filter((e) => e.category === "5"),
                        content6: workshopsList.filter((e) => e.category === "6"),
                        loading: false,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.signup_live().off();
        //this.props.firebase.signup(this.props.authUser.uid).off();
        this.props.firebase.workshops().off();
        this.props.firebase.signups().off();
    }

    render() {
        const { error, content, content1, content2, content3, content4, content5, content6, loading, error_msg, disable12, disable4, disable34, disable6, tally, active } = this.state;
        return (
            <div><AuthUserContext.Consumer>
                {authUser => (
            <div>
                {loading && <div>loading... </div>}
                {error && <div><div className="card text-white bg-danger mb-3" style={{ width: '96%', margin: '2%' }}>
                                <div className="card-header">Error</div>
                                <div className="card-body">
                                    <h4 className="card-title">{error_msg}</h4>
                                </div>
                            </div></div>}
                {(!loading && !error) && <div>
                <h1>Workshop Sign-Up</h1>
                <br />
                {!active && <div><div className="card text-white bg-danger mb-3">
                                <div className="card-header">Warning</div>
                                <div className="card-body">
                                    <h4 className="card-title">Workshop Sign-Ups are currently closed.</h4>
                                </div>
                            </div></div>}
                <div className="card text-white bg-warning mb-3">
                                <div className="card-header">Note</div>
                                <div className="card-body">
                                    <h4 className="card-title">If you are leading/co-leading a workshop, please don't sign up for anything during that period.</h4>
                                </div>
                            </div>
                <br />
                <h5>If you run into any issues with signing up for workshops, please contact Adam Tang (<a href="mailto:atang@ucls.uchicago.edu">atang@ucls.uchicago.edu</a>). I'll be happy to help!</h5>
                <br />
                <h3>Period 1</h3>
                <br />
                <WorkshopSelector content={content1} id={1} uid={authUser.uid} disable={disable12} tally={tally} active={active} />
                <br />
                <h3>Period 1 + 2 (double period)</h3>
                <br />
                <WorkshopSelector content={content4} id={4} uid={authUser.uid} disable={disable4} tally={tally} active={active} />
                <br />
                <h3>Period 2</h3>
                <br />
                <WorkshopSelector content={content2} id={2} uid={authUser.uid} disable={disable12} tally={tally} active={active} />
                <br />
                <h3>Period 3</h3>
                <br />
                <WorkshopSelector content={content3} id={3} uid={authUser.uid} disable={disable34} tally={tally} active={active} />
                <br />
                <h3>Period 3 + 4 (double period)</h3>
                <br />
                <WorkshopSelector content={content6} id={6} uid={authUser.uid} disable={disable6} tally={tally} active={active} />
                <br />
                <h3>Period 4</h3>
                <br />
                <WorkshopSelector content={content5} id={5} uid={authUser.uid} disable={disable34} tally={tally} active={active} />
                <br />
                </div>}
            </div>
            )}
            </AuthUserContext.Consumer></div>
        );
    }
}

const SignUpBase = withFirebase(SignUpStuff);

/*{content.map(workshop => (
            <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>{workshop.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Enrollment: {workshop.signup} / {workshop.capacity}</Card.Subtitle>
              <Card.Text>
                {workshop.placeholder}
              </Card.Text>
              <Card.Link href={`/view/${workshop.uid}`}>Read More</Card.Link>
              <br /><br /> 
              {workshop.signup === workshop.capacity ?
               <Button variant="success" disabled>Enroll</Button>
            :  <Button variant="success" onClick={this.enroll} workshopuid={workshop.uid} useruid={authUser.uid}>Enroll</Button>}
              
            </Card.Body>
          </Card>
        ))}*/

const condition = authUser => !!authUser && !!authUser.roles[ROLES.EARLY];

export default compose(withFirebase, withAuthorization(condition))(SignUp);