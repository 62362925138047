import React, { Component } from 'react';

import { withFirebase } from '../firebase';

import { Switch } from 'react-native';

import { compose } from 'recompose';
import { withAuthorization } from '../session';
import * as ROLES from '../../constants/roles';
import { Card, Row, Table } from 'react-bootstrap';


class ViewRosters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            content: [],
            content1: [],
            content2: [],
            content3: [],
            content4: [],
            content5: [],
            content6: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    const workshopsList = Object.keys(content).map(key => ({
                        ...content[key],
                        uuid: key,
                    })).reverse();
                    
                    this.setState({
                        content: workshopsList,
                        content1: workshopsList.filter((e) => e.category === "1"),
                        content2: workshopsList.filter((e) => e.category === "2"),
                        content3: workshopsList.filter((e) => e.category === "3"),
                        content4: workshopsList.filter((e) => e.category === "4"),
                        content5: workshopsList.filter((e) => e.category === "5"),
                        content6: workshopsList.filter((e) => e.category === "6"),
                        loading: false,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.workshops().off();
    }

    render() {
        const { error, content, content1, content2, content3, content4, content5, content6, loading } = this.state;
        return (
            <div>
                <h1>View Rosters</h1>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>Error</div>}
                {(!loading && !error) && <div>
                    <br />
                    <h3>Period 1</h3>
                    <br />
                    <WorkshopsList workshops={content1} />
                    <br />
                    <h3>Periods 1 and 2 (Double Period)</h3>
                    <br />
                    <WorkshopsList workshops={content4} />
                    <br />
                    <h3>Period 2</h3>
                    <br />
                    <WorkshopsList workshops={content2} />
                    <br />
                    <h3>Period 3</h3>
                    <br />
                    <WorkshopsList workshops={content3} />
                    <br />
                    <h3>Periods 3 and 4 (Double Period)</h3>
                    <br />
                    <WorkshopsList workshops={content6} />
                    <br />
                    <h3>Period 4</h3>
                    <br />
                    <WorkshopsList workshops={content5} />
                    <br />
                </div>}
                <br />
                
            </div>


        );
    }
}

const WorkshopsList = ({ workshops }) => (
    <div>
        <Row>{workshops.map(workshop => (
            <Card style={{ width: '17rem', margin: '0.5rem' }}>
            <Card.Body>
              <Card.Title>{workshop.title}</Card.Title>
              <Card.Link href={`/admin/view/${workshop.uid}`}>Check Roster</Card.Link>
            </Card.Body>
          </Card>
        ))}</Row>
    </div>
);

/* 
<h6>{numV} total voters</h6>
                <br />
                <h3>Grade President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={pres} scores={scores} />
                </div>}
                <br />
                <h3>Grade Vice President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={vp} scores={scores} />
                </div>}
                <br />
                <h3>Grade Cultural Union Representative</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={cu} scores={scores} />
                </div>}

const CandidatesList = ({ candidates, scores }) => (
    <ListGroup>
        {candidates.map(candidate => (
            <ListGroup.Item>{candidate.name} {scores[candidate.uuid - 2]}</ListGroup.Item>
        ))}
    </ListGroup>
);*/

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition), )(ViewRosters);
