import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { withFirebase } from '../firebase';

function ViewWorkshop(props) {
    const [loading, setLoading] = useState(true);
    const [workshop, setWorkshop] = useState(null);

    let { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        props.firebase.workshop(id).get().then(snapshot => {
            if (!snapshot.exists()) {
                navigate('/404', {replace:true});
            }
        });
        props.firebase.workshop(id).on('value', snapshot => {
            setWorkshop(snapshot.val());
            setLoading(false);
        });
        // return a function to execute at unmount
        return () => {
            props.firebase.workshop(id).off();
        }
      }, []);
    return (

        <div>
            {loading && <div>loading... </div>}
            {!loading && 
            <div>
                <h1>{workshop.title}</h1>
                <br />
                <h3>Location: {workshop.location}</h3>
                <br />
                <h5>{workshop.length == "2" ? <div>This is a double-period workshop.</div> : <div>This is a single-period workshop.</div>}</h5>
                <br />
                <h5>Capacity: {workshop.capacity}</h5>
                <br />
                <h5>Period(s): {workshop.periods}</h5>
                <br />
                <p>{workshop.description}</p>
            </div>}
        </div>

    );
}

export default withFirebase(ViewWorkshop);