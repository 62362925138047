import React from 'react';

const NotFound = () => (
    <div className="card text-white bg-danger mb-3" style={{ width: '96%', margin: '2%' }}>
        <div className="card-header">Error</div>
        <div className="card-body">
            <h4 className="card-title">Page Not Found</h4>
            <p className="card-text">Sorry, but that page doesn't exist.</p>
        </div>
    </div>
);

export default NotFound;