import React, { Component } from 'react';

import { withFirebase } from '../firebase';

import { Switch } from 'react-native';

import { compose } from 'recompose';
import { withAuthorization } from '../session';
import * as ROLES from '../../constants/roles';
import { Card, Row, Table } from 'react-bootstrap';


class NoSignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            valids: [],
            vuid: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        let vals = [];
        let vuids = [];
        this.props.firebase.valid_users().on('value', snapshot => {
            vals = snapshot.val();
            vuids = Object.keys(snapshot.val());
            this.setState({ valids: snapshot.val() });
        });
        let lelist = [];
        let leuid = [];
        this.props.firebase.users().on('value', snapshot => {
            const content = snapshot.val();
            const keys = Object.keys(content);
            lelist = content;
            leuid = keys;
            this.setState({list: content, uid: keys });
            let vuid = [];
            for (let i = 0; i < vuids.length; ++i) {
                let inData = false;
                for (let j = 0; j < keys.length; ++j) {
                    if (content[keys[j]].username === vuids[i]) {
                        inData = true;
                        break;
                    }
                }
                if (!inData) {
                    vuid.push(vuids[i]);
                }
            }
            this.setState({ vuid: vuid })

        });
        this.props.firebase.signups().on('value', snapshot => {
            const content = snapshot.val();
            this.setState({ loading: false });
        });
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
        this.props.firebase.signups().off();
        this.props.firebase.valid_users().off();
    }

    render() {
        const { error, loading, valids, vuid } = this.state;
        return (
            <div>
                {loading && <div>loading... </div>}
                {error && <div>Error</div>}
                {(!loading && !error) && <div>
                    <h1>Not Signed Up List</h1>
                <br />
                <h3>{vuid.length} total users not signed up for anything</h3>
                <br />  
                    <h5>Not Signed Up for Website</h5>
                    <br />
                    <UsersList users={valids} uidList={vuid} />
                </div>}
                <br />
                
            </div>


        );
    }
}

const UsersList = ({ users, uidList }) => (
    <div>
        
        <Table striped bordered hover>
        <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Grade</th>
          <th>LabNet</th>
        </tr>
      </thead>
      <tbody>
        {uidList.map(uid => (
            <tr>
            <td>{users[uid].first}</td>
            <td>{users[uid].last}</td>
            <td>{users[uid].grade}</td>
            <td>{uid}</td>
          </tr>
        ))}
        </tbody>
        </Table>
    </div>
);

/* 
<h6>{numV} total voters</h6>
                <br />
                <h3>Grade President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={pres} scores={scores} />
                </div>}
                <br />
                <h3>Grade Vice President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={vp} scores={scores} />
                </div>}
                <br />
                <h3>Grade Cultural Union Representative</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={cu} scores={scores} />
                </div>}

const CandidatesList = ({ candidates, scores }) => (
    <ListGroup>
        {candidates.map(candidate => (
            <ListGroup.Item>{candidate.name} {scores[candidate.uuid - 2]}</ListGroup.Item>
        ))}
    </ListGroup>
);*/

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition), )(NoSignUp);
