import React, { Component } from 'react';

import { withFirebase } from '../firebase';

import { Switch } from 'react-native';

import { compose } from 'recompose';
import { withAuthorization } from '../session';
import * as ROLES from '../../constants/roles';
import { Card, Row, Table } from 'react-bootstrap';


class ViewUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            uid: [],
            signups: [],
            uidsignups: [],
            workshops: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.users().on('value', snapshot => {
            this.setState({list: snapshot.val(), uid: Object.keys(snapshot.val()) });
        });
        this.props.firebase.workshops().on('value', snapshot => {
            this.setState({workshops: snapshot.val()});
        });
        this.props.firebase.signups().on('value', snapshot => {
            this.setState({signups: snapshot.val(), uidsignups: Object.keys(snapshot.val()), loading: false });
        });
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
        this.props.firebase.signups().off();
    }

    render() {
        const { error, loading, list, uid, signups, uidsignups, workshops } = this.state;
        return (
            <div>
                <h1>User List</h1>
                <br />
                <h3>To view a user's workshops, click on their User ID.</h3>
                <br />
                <h5>{uid.length} total users</h5>
                <br />
                <h5>{uidsignups.length} total users signed up</h5>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>Error</div>}
                {(!loading && !error) && <div>
                    <br />
                    <UsersList users={list} uidList={uid} workshops={workshops} signups={signups} />
                </div>}
                <br />
                
            </div>


        );
    }
}

const UsersList = ({ users, uidList, workshops, signups }) => (
    <div>
        
        <Table striped bordered hover>
        <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Grade</th>
          <th>Email</th>
          <th>User ID</th>
          <th>Workshop 1 (10:20 - 11:10)</th>
          <th>Workshop 2 (11:20 - 12:10)</th>
          <th>Workshop 3 (1:15 - 2:05)</th>
          <th>Workshop 4 (2:15 - 3:05)</th>
        </tr>
      </thead>
      <tbody>
        {uidList.map(uid => (
            <tr>
            <td>{users[uid].first}</td>
            <td>{users[uid].last}</td>
            <td>{users[uid].grade}</td>
            <td>{users[uid].email}</td>
            <td><a href={`/admin/viewschedule/${uid}`}>{uid}</a></td>
            {signups[uid] != null ?
             <td>{signups[uid]["1"] != null ? <div><a href={`/admin/view/${signups[uid]["1"].uid}`}>{workshops[signups[uid]["1"].uid].title}</a></div> : <div>{signups[uid]["4"] != null ? <div><a href={`/admin/view/${signups[uid]["4"].uid}`}>{workshops[signups[uid]["4"].uid].title}</a></div>:<div>none</div>}</div>} {signups[uid]["4"] != null && <div>({workshops[signups[uid]["4"].uid].location})</div>} {signups[uid]["1"] != null && <div>({workshops[signups[uid]["1"].uid].location})</div>}</td>
             : <td>none</td>
            }
           {signups[uid] != null ?
             <td>{signups[uid]["2"] != null ? <div><a href={`/admin/view/${signups[uid]["2"].uid}`}>{workshops[signups[uid]["2"].uid].title}</a></div> : <div>{signups[uid]["4"] != null ? <div><a href={`/admin/view/${signups[uid]["4"].uid}`}>{workshops[signups[uid]["4"].uid].title}</a></div>:<div>none</div>}</div>} {signups[uid]["4"] != null && <div>({workshops[signups[uid]["4"].uid].location})</div>} {signups[uid]["2"] != null && <div>({workshops[signups[uid]["2"].uid].location})</div>}</td>
             : <td>none</td>
            }
           {signups[uid] != null ?
             <td>{signups[uid]["3"] != null ? <div><a href={`/admin/view/${signups[uid]["3"].uid}`}>{workshops[signups[uid]["3"].uid].title}</a></div> : <div>{signups[uid]["6"] != null ? <div><a href={`/admin/view/${signups[uid]["6"].uid}`}>{workshops[signups[uid]["6"].uid].title}</a></div>:<div>none</div>}</div>} {signups[uid]["6"] != null && <div>({workshops[signups[uid]["6"].uid].location})</div>} {signups[uid]["3"] != null && <div>({workshops[signups[uid]["3"].uid].location})</div>}</td>
             : <td>none</td>
            }
           {signups[uid] != null ?
             <td>{signups[uid]["5"] != null ? <div><a href={`/admin/view/${signups[uid]["5"].uid}`}>{workshops[signups[uid]["5"].uid].title}</a></div> : <div>{signups[uid]["6"] != null ? <div><a href={`/admin/view/${signups[uid]["6"].uid}`}>{workshops[signups[uid]["6"].uid].title}</a></div>:<div>none</div>}</div>} {signups[uid]["6"] != null && <div>({workshops[signups[uid]["6"].uid].location})</div>} {signups[uid]["5"] != null && <div>({workshops[signups[uid]["5"].uid].location})</div>}</td>
             : <td>none</td>
            }
          </tr>
        ))}
        </tbody>
        </Table>
    </div>
);

/* 
<h6>{numV} total voters</h6>
                <br />
                <h3>Grade President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={pres} scores={scores} />
                </div>}
                <br />
                <h3>Grade Vice President</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={vp} scores={scores} />
                </div>}
                <br />
                <h3>Grade Cultural Union Representative</h3>
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <CandidatesList candidates={cu} scores={scores} />
                </div>}

const CandidatesList = ({ candidates, scores }) => (
    <ListGroup>
        {candidates.map(candidate => (
            <ListGroup.Item>{candidate.name} {scores[candidate.uuid - 2]}</ListGroup.Item>
        ))}
    </ListGroup>
);*/

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition), )(ViewUsers);
