import React from 'react';

const Denied = () => (
    <div className="card text-white bg-danger mb-3" style={{ width: '96%', margin: '2%' }}>
        <div className="card-header">Error</div>
        <div className="card-body">
            <h4 className="card-title">Access Denied</h4>
            <p className="card-text">You don't have permission to view this page or have logged in prior to the release date for non-workshop leaders (9:00 PM 2/21/2024). Once enrollment starts, just reloading the page will not let you in; you will have to click "Home" and reclick "Workshop Sign-Up" in the navigator bar. If you believe this is an error, please contact Adam Tang (atang@ucls.uchicago.edu).  </p>
        </div>
    </div>
);

export default Denied;