import React from "react";
import { compose } from 'recompose';
import { withFirebase } from "../firebase";
import AuthUserContext from "./context";
import { withRouter } from "../weird_router_stuff/withRouter";

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(authUser => {
                if (!condition(authUser)) {
                    this.props.navigate('/403', {replace:true});
                }
            }, authSuccess => {
                if (!authSuccess) {
                    this.props.navigate('/', {replace:true});
                    this.props.firebase.doSignOut();
                    console.log("hello jeff");
                } else {
                    this.props.firebase.doSignIn();
                }
            });
        }
        componentWillUnmount() {
            this.listener();
        }
        render() {
            return (
                <AuthUserContext.Consumer>
            {authUser => condition(authUser) ? <Component {...this.props} /> : null}
            </AuthUserContext.Consumer>);
        }
    }


    return compose(
        withFirebase,
        withRouter,
    )(WithAuthorization);
};

export default withAuthorization;