import React, { Component } from 'react';

import { withFirebase } from '../firebase';

import { Switch } from 'react-native';

import { compose } from 'recompose';
import { withAuthorization } from '../session';
import * as ROLES from '../../constants/roles';
import { Card, Row, Table } from 'react-bootstrap';
// hi

class AdminPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            content: [],
            content1: [],
            content2: [],
            content3: [],
            content4: [],
            content5: [],
            content6: [],
            active: false,
            list: [],
            uid: [],
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.signup_live().get().then(snapshot => {
            if (snapshot.val().value == 1) {
                this.setState({ active: true });
            }
        });
        this.props.firebase.users().on('value', snapshot => {
            this.setState({list: snapshot.val(), uid: Object.keys(snapshot.val())});
        });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    const workshopsList = Object.keys(content).map(key => ({
                        ...content[key],
                        uuid: key,
                    })).reverse();
                    
                    this.setState({
                        content: workshopsList,
                        content1: workshopsList.filter((e) => e.category === "1"),
                        content2: workshopsList.filter((e) => e.category === "2"),
                        content3: workshopsList.filter((e) => e.category === "3"),
                        content4: workshopsList.filter((e) => e.category === "4"),
                        content5: workshopsList.filter((e) => e.category === "5"),
                        content6: workshopsList.filter((e) => e.category === "6"),
                        loading: false,
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.props.firebase.workshops().off();
        this.props.firebase.users().off();
    }

    signupChanged = (value) => {
        this.setState({ active: value });
        var set = "0";
        if (value) {
            set = "1";
        }
        this.props.firebase.signup_live().set({value: set});
    };

    render() {
        const { error, content, content1, content2, content3, content4, content5, content6, loading, active, list, uid } = this.state;
        return (
            <div>
                <h1>Artsfest Admin Panel</h1>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>Error</div>}
                {(!loading && !error) && <div>
                    <h3>Toggle Active Signup</h3>
                    <br />
                    <Switch onValueChange={this.signupChanged} value={active} />
                    <br /> <br />
                    <h3><a href="/admin/viewrosters">View Workshop Rosters</a></h3>
                    <br />
                    <h3><a href="/admin/users">View Users List</a></h3>
                </div>}
                <br />
                
            </div>


        );
    }
}

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition), )(AdminPage);
