import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';

import { AuthUserContext, withAuthorization } from '../session';

import { Table } from 'react-bootstrap';

const ViewSchedule = () => (
    <AuthUserContext.Consumer>
    {authUser => (
  <div>
    <ScheduleBase authUser={authUser} />
  </div>)}
</AuthUserContext.Consumer>
);

class ViewScheduleStuff extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            content: [],
            uid1: null,
            uid2: null,
            uid3: null,
            uid4: null,
            error: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        console.log("on");
        this.props.firebase.signup(this.props.authUser.uid).on('value', snapshot => {
            const content = snapshot.val();
            if (snapshot.exists()) {
                if (content["1"] != null) {
                    this.setState({uid1: content["1"].uid});
                }
                if (content["2"] != null) {
                    this.setState({uid2: content["2"].uid});
                }
                if (content["3"] != null) {
                    this.setState({uid3: content["3"].uid});
                }
                if (content["4"] != null) {
                    this.setState({uid1: content["4"].uid, uid2: content["4"].uid});
                }
                if (content["5"] != null) {
                    this.setState({uid4: content["5"].uid});
                }
                if (content["6"] != null) {
                    this.setState({uid3: content["6"].uid, uid4: content["6"].uid});
                }

            }
        });
        this.props.firebase.workshops().get().then(snapshot => {
            if (!snapshot.exists()) {
                this.setState({ error: true, loading: false });
            } else {
                this.props.firebase.workshops().on('value', snapshot => {
                    const content = snapshot.val();
                    
                    this.setState({
                        content: content,
                        loading: false,
                    });
                });
            }
        });
        
    }

    componentWillUnmount() {
        this.props.firebase.workshops().off();
        this.props.firebase.signup(this.props.authUser.uid).off();
    }

    render() {
        const { error, content, uid1, uid2, uid3, uid4, loading } = this.state;
        return (
            <div>
                <h1>My Schedule</h1>
                <br />
            {loading && <div>loading... </div>}
            {!loading && 
            <div>
                <br />
                <h5>To view the workshop signup list, click on the workshop title.</h5>
                <br />
                <h7>If you did not sign up for a workshop period or forgot to and your schedule says "none" in a time slot, refer to <a href="https://docs.google.com/spreadsheets/d/1XxLFbBLdG4gug4-bG_a_CnzOhsimxkZQhOVf4FTJP64/edit#gid=0" target="_blank" rel="noopener noreferrer">this spreadsheet</a> to determine which workshops to go to.</h7>
                <br />
                <br />
                <br />
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <Table striped bordered hover>
                    <thead>
                    <tr>
                    <th>Period</th>
                    <th>Time</th>
                    <th>Workshop Name</th>
                    <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                        <tr>
                        <td>1</td>
                        <td>10:20 - 11:10</td>
                        <td>{uid1 != null ? <div>{content[uid1].title}</div>: <div>none</div>}</td>
                        <td>{uid1 != null ? <div>{content[uid1].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>11:20 - 12:10</td>
                        <td>{uid2 != null ? <div>{content[uid2].title}</div>: <div>none</div>}</td>
                        <td>{uid2 != null ? <div>{content[uid2].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>1:15 - 2:05</td>
                        <td>{uid3 != null ? <div>{content[uid3].title}</div>: <div>none</div>}</td>
                        <td>{uid3 != null ? <div>{content[uid3].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>2:15 - 3:05</td>
                        <td>{uid4 != null ? <div>{content[uid4].title}</div>: <div>none</div>}</td>
                        <td>{uid4 != null ? <div>{content[uid4].location}</div>: <div>none</div>}</td>
                    </tr>
                    </tbody>
                    </Table>
                </div>}
            </div>}
        </div>

    );
    }
}

/*
return (
            <div>
                <h1>My Schedule</h1>
                <br />
                <h3>Please view your workshop lineup <a href="https://docs.google.com/spreadsheets/d/1UwwzyhklQoln0CEuU1Y3nywkP-jDF-IBn9P6dwitfEM/edit?usp=sharing">here</a>.</h3>
            </div>

        );
<br />
<h4>If you are leading or co-leading a workshop or several workshops, they should appear as "none" here. For example, if you are leading a 2nd period workshop, your schedule should display "none" for your second period.</h4>
                <br />
                <h5>To read workshop descriptions, click on their corresponding workshop names.</h5>
                <br />
                {loading && <div>loading... </div>}
                {error && <div>No entries</div>}
                {(!loading && !error) && <div>
                    <Table striped bordered hover>
                    <thead>
                    <tr>
                    <th>Period</th>
                    <th>Time</th>
                    <th>Workshop Name</th>
                    <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                        <tr>
                        <td>1</td>
                        <td>10:10 - 11:10</td>
                        <td>{uid1 != null ? <div><a href={`/view/${uid1}`}>{content[uid1].title}</a></div>: <div>none</div>}</td>
                        <td>{uid1 != null ? <div>{content[uid1].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>11:20 - 12:20</td>
                        <td>{uid2 != null ? <div><a href={`/view/${uid2}`}>{content[uid2].title}</a></div>: <div>none</div>}</td>
                        <td>{uid2 != null ? <div>{content[uid2].location}</div>: <div>none</div>}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>1:20 - 2:20</td>
                        <td>{uid3 != null ? <div><a href={`/view/${uid3}`}>{content[uid3].title}</a></div>: <div>none</div>}</td>
                        <td>{uid3 != null ? <div>{content[uid3].location}</div>: <div>none</div>}</td>
                    </tr>
                    </tbody>
                    </Table>*/

const ScheduleBase = withFirebase(ViewScheduleStuff);


const condition = authUser => !!authUser;

export default compose(withFirebase, withAuthorization(condition))(ViewSchedule);